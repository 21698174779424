import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import SVTeamHomeContainer from '@apps/www/src/www/containers/SVTeamHomeContainer';
import useAuthHasTeamPrivileges from '@apps/www/src/www/hooks/useAuthHasTeamPrivileges';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import usePaginatedQuery from '@pkgs/shared-client/hooks/usePaginatedQuery';
import TeamFeedItemsQuery from '@apps/www/src/www/queries/TeamFeedItemsQuery';
import SVGrid from '@pkgs/shared-client/components/SVGrid';

const pages: { to: string; label: string }[] = [
	{ to: '/team/users/', label: 'Users' },
	{ to: '/team/settings/', label: 'Team Settings' },
];

const _SVTeamFeedPage = () => {
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();
	const hasTeamPrivileges = useAuthHasTeamPrivileges();

	const { items, loading, paginate } = usePaginatedQuery(TeamFeedItemsQuery, {
		skip: !hasTeamPrivileges || !isLoggedIn || !team,
		fetchPolicy: 'no-cache',
		// No SSR here to speed up first load, feed is usually a slow query.
		ssr: false,
		// No need to check for errors here as this is the only page the user can load to access navigation
		errorPolicy: 'ignore',
	});

	if (!hasTeamPrivileges || !isLoggedIn || !team) {
		if (team) {
			return <SVRedirect to="/team/users/" />;
		} else {
			return <SVRedirect to="/team/create/" />;
		}
	}

	return (
		<SVTeamHomeContainer>
			<SVGridContainer
				sourceType={SVGrid.SOURCE_TYPES.TEAM_FEED}
				items={items}
				isLoading={loading}
				onPaginate={paginate}
			/>
		</SVTeamHomeContainer>
	);
};

const SVTeamFeedPage = _SVTeamFeedPage as typeof _SVTeamFeedPage & {
	routes: typeof pages;
};

SVTeamFeedPage.routes = pages;

export default SVTeamFeedPage;
