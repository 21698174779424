import { UserFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAuthBoards from '@apps/www/src/www/hooks/useAuthBoards';
import useAuthHasTeamPrivileges from '@apps/www/src/www/hooks/useAuthHasTeamPrivileges';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVBoardsMenu from '@pkgs/shared-client/components/SVBoardsMenu';
import SVFeedCTAs from '@pkgs/shared-client/components/SVFeedCTAs';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';

const SVTeamHomeContainer = ({ children }: { children: React.ReactNode }) => {
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();
	const user = useAuthUser([
		'_id',
		'name',
		'username',
		'url',
		'avatarURL',
	]) as UserFragmentFragment;
	const hasTeamPrivileges = useAuthHasTeamPrivileges();
	const teamBoards = useAuthBoards()?.filter(
		(board) => board.ownershipType === BoardOwnershipType.TEAM,
	);

	if (!hasTeamPrivileges || !isLoggedIn || !team || !user) {
		if (team) {
			return <SVRedirect to="/team/users/" />;
		} else {
			return <SVRedirect to="/team/create/" />;
		}
	}

	return (
		<div className="-md:flex-col relative flex w-full">
			<SVFeedCTAs />
			{teamBoards && teamBoards.length > 0 && (
				<div className="w-full">
					<div className="mb-6 flex">
						<SVPageMargins>
							<SVBoardsMenu
								boards={teamBoards}
								feedboardid={team.feedBoardID || ''}
								teamFeedAsHomepage={team.teamFeedAsHomepage}
								user={user}
								isMenuVisible={true}
								isHorizontal={true}
							/>
						</SVPageMargins>
					</div>
					<div className="flex-1">{children}</div>
				</div>
			)}
		</div>
	);
};

export default SVTeamHomeContainer;
