import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import SVLink from '@pkgs/shared-client/components/SVLink';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import clsx from 'clsx';
import { useRouter } from 'next/router';

type Item = {
	to: string;
	label: string;
	matchUrl?: string;
	target?: string;
};

const SVFeedCTAs = () => {
	const router = useRouter();
	const team = useAuthTeam();

	const items: Item[] = [
		{
			to: team?.teamFeedAsHomepage ? '/feed/' : '/',
			label: 'Feed',
		},
		team && {
			to: team.teamFeedAsHomepage ? '/' : '/team/',
			label: team.name,
			matchUrl: '/team/**',
		},
		{ to: '/course/', label: 'Learn', matchUrl: '/course/**' },
	].filter(Boolean);

	return (
		<div className="sm:hidden">
			<div className="w-full h-full py-6 flex-center bg-background space-x-7">
				{items.map((item) => (
					<SVLink
						key={item.to}
						to={item.to}
						className={clsx(
							'max-w-[30vw] truncate text-lg font-medium',
							matchLocation(router.asPath, String(item.to)) ||
								(item.matchUrl &&
									matchLocation(router.asPath, String(item.matchUrl)))
								? 'text-primary'
								: 'text-gray-500',
						)}
					>
						{item.label}
					</SVLink>
				))}
			</div>
		</div>
	);
};

export default SVFeedCTAs;
