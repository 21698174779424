/**
 * We display this message this to block the feed and search page when users have an expired subscription.
 */

import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';

export default function SVSubscriptionExpiredNotice({
	source,
}: {
	source: ValueOf<typeof UpgradeButtonSource>;
}) {
	return (

		<div className="flex min-h-[calc(100vh-var(--nav-height)*2)] flex-col items-center justify-center relative">
			<div className="flex max-w-80 p-6 flex-col items-center justify-center space-y-4 text-center">
				<p className="type-base mb-6 text-gray-50">Your inspiration is waiting</p>
				<p className="type-small text-gray-400">Reactivate Premium to keep exploring fresh, ad-free inspiration.</p>
				<p className="type-small text-gray-400">
					You're supporting an independent team dedicated to creating a focused,
					distraction-free space built for you.
				</p>

				<div>
					<SVTrackedUpgradeButtonContainer
						className="mt-16 bg-gray-200"
						source={source}
						to="/upgrade/"
						use={SVButtonUSES.PRIMARY}
						size={SVButtonSIZES.SMALL}
					>
						Go back to Premium
					</SVTrackedUpgradeButtonContainer>
				</div>
			</div>
			<div className="flex type-label absolute -bottom-14 -sm:bottom-0 text-gray-500">
				Read <SVLink to="https://inspire.savee.it/why-savee-doesnt-have-a-free-plan/" className="underline ml-1 type-label"> why we don't have a free plan.</SVLink>
			</div>
		</div>
	);
}
