import {
	type BoardFragmentFragment,
	type UserFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVLink from '@pkgs/shared-client/components/SVLink';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import { useRouter } from 'next/router';

// TODO: Cleanup this component, only used on team boards now

interface SVBoardsMenuProps {
	boards: BoardFragmentFragment[];
	feedboardid?: string;
	teamFeedAsHomepage?: boolean;
	userurl?: string;
	user?: UserFragmentFragment;
	isMenuVisible?: boolean;
	isHorizontal?: boolean;
}

const _AllBoardsLink = ({ to, user, isTeamView }: { to: string; user; isTeamView: boolean }) => (
	<SVLink to={to} className="-md:whitespace-nowrap -mdp:mr-2 flex items-center">
		<div className="flex items-center">
			<p>{isTeamView ? 'All' : 'All Boards'}</p>
			{!isTeamView && (
				<span className={clsx('ml-2', { underline: !isActive })}>({user.boardsCount})</span>
			)}
		</div>
	</SVLink>
);

const _AllSavesLink = ({ to, user }: { to: string; user }) => (
	<SVLink to={to} className="-md:whitespace-nowrap -mdp:mr-2 flex items-center">
		<div className="flex items-center">
			<p>All Saves</p>
			<span className={clsx('ml-2', { underline: !isActive })}>({user.itemsCount})</span>
		</div>
	</SVLink>
);

const _BoardLink = ({
	board,
	isActive,
	_hasFeedboardId,
}: {
	board: BoardFragmentFragment;
	isActive: boolean;
	_hasFeedboardId: boolean;
}) => {
	const boardUrl = board.ownershipType === 'team' ? `/team/boards/${board.slug}` : board.url;

	return (
		<li
			key={board._id}
			className={clsx('-mdp:mr-2 group rounded-lg', {
				'items-center text-gray-200': isActive,
				'text-gray-500 transition duration-150 hover:text-gray-300': !isActive,
			})}
		>
			<SVLink to={boardUrl} className="-md:whitespace-nowrap">
				<div className="flex items-center">
					<p className="max-w-full truncate">{board.name}</p>
					<span className={clsx('ml-2', { underline: !isActive })}>
						({board.itemsCount})
					</span>
				</div>
			</SVLink>
		</li>
	);
};

const isActive = (board: BoardFragmentFragment, routerPath: string) => {
	let updatedRouterPath = routerPath;
	const boardPath = new URL(board.url).pathname;

	if (routerPath.startsWith('/team/') && !routerPath.includes('/boards/')) {
		updatedRouterPath = routerPath.replace('/team/', '/team/boards/');
	}

	return updatedRouterPath === boardPath;
};

const SVBoardsMenu = ({
	boards,
	feedboardid,
	teamFeedAsHomepage = false,
	userurl,
	user,
	isHorizontal = false,
}: SVBoardsMenuProps) => {
	const router = useRouter();

	const boardsArray = Array.isArray(boards) ? boards : [boards];
	const isTeamView = router.asPath.includes('/team') || !!teamFeedAsHomepage;
	const userPath = userurl ? new URL(userurl).pathname : '';

	const filteredBoards = boardsArray.filter(
		(board) =>
			board.ownershipType ===
			(isTeamView ? BoardOwnershipType.TEAM : BoardOwnershipType.USER),
	);

	return (
		<nav className={clsx('relative', { 'w-full': isHorizontal })}>
			<div>
				<ul
					className={clsx('scrollbar-hide overflow-x-auto', {
						'flex-col space-y-3': !isHorizontal,
						'flex space-x-6': isHorizontal,
					})}
				>
					{!isTeamView && (
						<li
							className={clsx('items-center', {
								'text-gray-200': router.asPath === userPath,
								'text-gray-500 transition duration-150 hover:text-gray-300':
									router.asPath !== userPath,
							})}
						>
							<_AllSavesLink to={`${userurl}`} user={user} />
						</li>
					)}

					{!feedboardid && isTeamView && (
						<li
							className={clsx('items-center', {
								'text-gray-200':
									router.asPath === '/' || router.asPath === '/team/',
								'text-gray-500 transition duration-150 hover:text-gray-300':
									router.asPath !== '/' && router.asPath !== '/team/',
							})}
						>
							<_AllBoardsLink to="/team" user={user} isTeamView={isTeamView} />
						</li>
					)}

					{feedboardid &&
						filteredBoards
							.filter((board) => board._id === feedboardid)
							.map((board) => (
								<_BoardLink
									key={board._id}
									board={board}
									isActive={
										router.asPath === '/' || router.asPath === '/team/'
											? board._id === feedboardid
											: isActive(board, router.asPath)
									}
									_hasFeedboardId={!!feedboardid}
								/>
							))}

					{filteredBoards
						.filter((board) => !feedboardid || board._id !== feedboardid)
						.map((board) => (
							<_BoardLink
								key={board._id}
								board={board}
								isActive={isActive(board, router.asPath)}
								_hasFeedboardId={!!feedboardid}
							/>
						))}
				</ul>
			</div>
		</nav>
	);
};

export default SVBoardsMenu;
